import React, { FC, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { SpecialButton } from './SpecialButton';
import { TableStore } from '../Table';
import { observer } from 'mobx-react';

interface ISpecialButtonCanvas {
    store: TableStore;
}

export const SpecialButtonCanvas: FC<ISpecialButtonCanvas> = observer(
    ({ store }) => {
        return (
            <Canvas
                gl={{ alpha: true }}
                camera={{
                    fov: 60,
                    far: 60000,
                }}
                onCreated={({ gl }) => {
                    gl.setClearColor('#ffffff');
                }}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
            >
                <ambientLight intensity={1.5} />
                <directionalLight
                    position={[0, 1, 1]}
                   // angle={0.15}
                   // penumbra={1}
                    intensity={1}
                />

                <SpecialButton
                    rotation={[Math.PI / 3.2, Math.PI / 20, 0]}
                    scale={4.2}
                    position={[0,-0.0,0]}
                    store={store}
                />
            </Canvas>
        );
    },
);
