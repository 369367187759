import * as THREE from 'three';
import React, { FC, useRef, useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/three';
import { useGLTF, Html, Text } from '@react-three/drei';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { observer } from 'mobx-react';
import { CompetitionStore } from '../Host/store';
import { mainStore } from '../Main';
import { TableStore } from '../Table';
import { useParams } from 'react-router-dom';
//@ts-ignore
import fontDemi from '../Fonts/AvenirNextCyr-Demi.woff';
import {palette} from '../../ColorManagement'
//@ts-ignore
import fontBlack from '../Fonts/AvenirNextCyr-Heavy.woff';

export const GLB_PATH = '../../assets/button.glb';

type GLTFResult = GLTF & {
    nodes: {
        red_button_: THREE.Mesh;
        button_base: THREE.Mesh;
        button_floor: THREE.Mesh;
    };
    materials: {
        Button: THREE.MeshStandardMaterial;
        button_base_material: THREE.MeshStandardMaterial;
        button_floor_material: THREE.MeshStandardMaterial;
    };
};

type ISpecialButton = JSX.IntrinsicElements['group'] & {
    store: TableStore;
};

export const SpecialButton: FC<ISpecialButton> = observer((props) => {
    const group = useRef<THREE.Group | null>(null);
    const { nodes, materials } = useGLTF(GLB_PATH) as GLTFResult;

    const compStore = CompetitionStore.getInstance();
    let { tableHash } = useParams();

    const table = mainStore.tables.find((el) => el.hash === tableHash);

    let tStore: TableStore | null = table
        ? TableStore.getInstance(table.id)
        : null;

    const store = props.store;
    const isActive = compStore.active_table === store.id;
    const isReady =
        store.params?.rounds?.[String(compStore.current_round_id)]?.['status'] ===
        'ready';

        useEffect(() => {
            return () => {
                tStore?.destroy();
                tStore = null;
            };
        }, []);
    
        if (table === undefined) {
            return (
                <>{`Ошибка: неправильный идентификатор стола: ${tableHash}. Такого стола не существует`}</>
            );
        }
    

    const { position } = useSpring({
        from: {
            position: !isReady ? [0, 0.34, 0] : [0, 0.28, 0],
        },

        to: {
            position: !isReady ? [0, 0.28, 0] : [0, 0.34, 0],
        },

        config: {
            friction: 50,
        },

        delay: 10,
    });

    return (
        <>
                        <Text
                    fontSize={0.3}
                    maxWidth={2}
                    lineHeight={0.8}
                    letterSpacing={0}
                    textAlign="center"
                    font={fontBlack}
                    anchorX="center"
                    anchorY="middle"
                    position={[0,-1.5,0 ]}
                    rotation={[ 0, 0, 0 ]}
                >
                    <meshBasicMaterial toneMapped = {false} color={palette.brandBlue} />

                    {table.name.toLocaleUpperCase()} 
                    
                </Text>

                <Text
                    fontSize={0.2}
                    maxWidth={1}
                    lineHeight={0.8}
                    letterSpacing={0}
                    textAlign="center"
                    font={fontBlack}
                    anchorX="center"
                    anchorY="middle"
                    position={[0,-2,0 ]}
                    rotation={[ 0, 0, 0 ]}
                >
                    <meshBasicMaterial toneMapped = {false} color={palette.brandOrange} />

                 
                    {isActive?  `Ваш ход` : `Дождитесь своей очереди`}
                </Text>


    
        <group
            ref={group}
            {...props}
            dispose={null}
        >
            {/* @ts-ignore */}
            <animated.group position={position}>
                <mesh
                    geometry={nodes.red_button_.geometry}
                    material={
                        !isActive
                            ? new THREE.MeshPhysicalMaterial({ color: palette.gray})
                            : !isReady
                            ? materials.Button
                            : new THREE.MeshNormalMaterial()
                    }
                    position={[0, 0, 0]}
                    scale={[0.17, 0.04, 0.17]}
                    onClick={async (event: any) => {
                        if (!isActive || !isReady) {
                            return;
                        }
                        await compStore.run(store.id);
                        // return !res || on ? setOn(false) : setOn(true)
                    }}
        
                />

                <mesh
                    geometry={nodes.button_base.geometry}
                    material={new THREE.MeshPhysicalMaterial({color: palette.brandOrange})}
                    position={[0, -0.06, 0]}
                    scale={[0.19, 0.04, 0.19]}
                />
            </animated.group>
            <mesh
                geometry={nodes.button_floor.geometry}
                material={new THREE.MeshPhysicalMaterial({ color: palette.brandBlue})}
                position={[0, 0.17, 0]}
                rotation={[-Math.PI, 0, -Math.PI]}
                scale={[-0.25, -0.08, -0.25]}
            />
        </group>
        </>
    );
});

useGLTF.preload(GLB_PATH);
//toneMapped = {false}