import { action, makeObservable, observable, runInAction } from 'mobx';

import { API_URL, COMPETITION_ID, http } from '../../constants';
import { ISector } from './store';

export interface IQuestionsAPI {
    whole_word_score: number;
    sectors: ISector[];
    rounds: {
        id: number;
        words: {
            id: number;
            name: string;
            description: string;
            hints: string[];
        }[];
        quiz: {
            id: number;
            question: string;
            answer: string;
        }[];
    }[];
}

export class PrivateStore {
    loading: boolean = true;
    settings: IQuestionsAPI | null = null;
    static _instance: PrivateStore | null = null;

    private constructor() {
        makeObservable(this, {
            loading: observable,
            settings: observable,
            init: action,
        });

        this.init();
    }

    static getInstance() {
        if (PrivateStore._instance === null) {
            PrivateStore._instance = new PrivateStore();
        }
        return PrivateStore._instance;
    }

    async init() {
        // TODO try-catch
        const resp = await http.get<{ params: IQuestionsAPI }>(
            `${API_URL}question/1`,
        );
        
        runInAction(() => {
            if (resp.data) {
                const { whole_word_score, sectors, rounds } = resp.data.params;
                this.settings = {
                    whole_word_score,
                    sectors,
                    rounds,
                };
                this.loading = false;
            }
        });
    }

    currentWord(currentRound: number, currentWordId: number) {
        return this.settings?.rounds
            ?.find((el) => el.id === currentRound)
            ?.words?.find(
                (el) =>
                    el.id === currentWordId,
            );
    }

    currentQuiz(currentRound: number, currentQuizId: number) {
        return this.settings?.rounds
            ?.find((el) => el.id === currentRound)
            ?.quiz?.find(
                (el) =>
                    el.id === currentQuizId,
            );
    }

    currentWordLetters(currentRound: number, currentWordId: number) {
        return Object.values(
            this.currentWord(currentRound, currentWordId)?.name ?? '',
        );
    }
}
