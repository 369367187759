import { AxiosError } from 'axios';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { toast } from 'react-hot-toast';

import { API_URL, COMPETITION_ID, http, sleep } from '../../constants';
import { dateTimeParse } from '@gravity-ui/date-utils';

export interface ISector {
    id: number;
    score: number;
    factor: number;
    prize: boolean;
    type: 'letter' | 'question';
    color: string;
}

export type ICompetitionMeta = Partial<{
    round_len_sec: number;
    round_start_time: string; 
}>;

export type ICompetition_Meta = Partial<{
    round_len_min: number;
}>;

export interface ICompetitionParams {
    active_table: number | null ;
    selected_table: number;
    current_sector_id: number;
    current_round_id: number;
    meta: ICompetitionMeta;
    current_rounds: ICurrentRound[];
}

export interface ICompetition {
    id: string;
    name: string;
    params: ICompetitionParams;
}

export interface ICurrentRound {
    id: number,
    word_id: null | number,
    quiz_id: null | number,
    hint_id: null | number,
    opened_letters: boolean[]
}

export class CompetitionStore {
    loading: boolean = true;

    active_table: number | null = null;
    selected_table: number = 1;
    current_sector_id: number = 1;
    current_round_id: number = 1;
    meta: ICompetitionMeta = {};
    _meta: ICompetition_Meta = {};
    current_rounds: ICurrentRound[] = [];

    static _instance: CompetitionStore | null = null;

    private constructor() {
        makeObservable(this, {
            loading: observable,
            active_table: observable,
            selected_table: observable,
            current_sector_id: observable,
            current_round_id: observable,
            meta: observable,
            _meta: observable,
            current_rounds: observable,
            shiftSeconds: computed,
            current_word_id: computed,
            current_quiz_id: computed,
            current_opened_letters: computed,
            init: action,
            activate: action,
        });

        this.init();
    }

    get shiftSeconds() {
        let shiftSeconds: number | null = null;
        if (this.meta.round_len_sec && this.meta.round_start_time) {
            const round_start_time = dateTimeParse(
                this.meta.round_start_time!,
            )!;
            shiftSeconds = this.meta.round_len_sec -
                (dateTimeParse('now')?.diff(round_start_time, 'seconds') ?? 0);
            return shiftSeconds;
        } else {
            return null;
        }
    }

    static getInstance() {
        if (CompetitionStore._instance === null) {
            CompetitionStore._instance = new CompetitionStore();
        }
        return CompetitionStore._instance;
    }

    get current_word_id() {
        let round = this.current_rounds.find(el => el.id === this.current_round_id);
        return round?.word_id ?? 1
    }

    get current_quiz_id() {
        let round = this.current_rounds.find(el => el.id === this.current_round_id);
        return round?.quiz_id ?? 1
    }

    get current_opened_letters() {
        let round = this.current_rounds.find(el => el.id === this.current_round_id);
        return round?.opened_letters ?? null;
    }

    async init() {
        // TODO try-catch
        const resp = await http.get<ICompetition>(
            `${API_URL}competiton/${COMPETITION_ID}/`,
        );

        runInAction(() => {
            if (resp.data.params) {
                const {
                    active_table,
                    selected_table,
                    current_sector_id,
                    current_round_id,
                    current_rounds,
                    meta = {},
                } = resp.data.params;
                this.active_table = active_table;
                this.selected_table = selected_table;
                this.current_sector_id = current_sector_id;
                this.current_round_id = current_round_id;
                this.current_rounds = current_rounds;
                this.meta = meta;
                this.loading = false;
            }
        });

        await sleep();

        await this.init();
    }

    async activate(tableId: number) {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/activate/`,
                {
                    table_id: tableId,
                },
            );
            runInAction(() => {
                this.active_table = tableId;
                this.loading = false;
            });
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async deactivate(tableId: number) {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/deactivate/`,
                {
                    table_id: tableId,
                },
            );
            runInAction(() => {
                this.active_table = null;
                this.loading = false;
            });
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async select(tableId: number) {
        this.loading = true;
        try {
            await http.post(`${API_URL}competiton/${COMPETITION_ID}/select/`, {
                table_id: tableId,
            });
            runInAction(() => {
                this.selected_table = tableId;
                this.loading = false;
            });
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async plusMinus(tableId: number, count: number) {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/plusMinus/`,
                {
                    table_id: tableId,
                    count,
                },
            );
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async nextWord() {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/next_word/`,
                {},
            );
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async nextQuiz() {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/next_quiz/`,
                {},
            );
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async setMeta(meta: ICompetitionMeta) {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/set_meta/`,
                {meta},
            );
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async prevQuiz() {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/prev_quiz/`,
                {},
            );
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async openLetter({tableId, letter, count, quiz}: {tableId: number, letter: string, count: number | null, quiz: number}) {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/open_letter/`,
                {
                    table_id: tableId,
                    letter,
                    count,
                    quiz
                },
            );
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    async run(tableId: number) {
        this.loading = true;
        try {
            const resp = await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/run/`,
                {
                    table_id: tableId,
                },
            );
        } catch (error: any) {
            // TODO
            // toast.error(
            //     (error as AxiosError<string>).response?.data ?? error?.message,
            // );
            return;
        }
    }

    async clearScores() {
        this.loading = true;
        try {
            await http.post(
                `${API_URL}competiton/${COMPETITION_ID}/clear_scores/`,
            );
        } catch (error: any) {
            this.toast_error(error)
        }
    }

    toast_error(error: any) {
        let msg = '';
        if(typeof error !== 'object') {
            msg = "UNKNOWN ERROR";
        }
        msg = error?.message;
        if (error instanceof AxiosError) {
            if (typeof error.response?.data === 'string') {
                msg = JSON.stringify(error.response?.data)
            } else {
                msg = JSON.stringify(error.response?.data).substring(0, 250);
            }
        }
        toast.error(msg);
    }
}
