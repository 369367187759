import { action, makeObservable, observable, runInAction } from 'mobx';

import { API_URL, COMPETITION_ID, http, sleep } from '../../constants';
import { TableParams } from '../Table';

export interface TableListElement {
    id: number;
    hash: string;
    name: string;
    params: TableParams;
}

export interface TeamsListElementParams {
    id: number;
    tables: number[];
}

export interface TeamsListElement {
    params: TeamsListElementParams[];
}

class MainStore {
    tables: TableListElement[] = [];
    teams: TeamsListElementParams[] = [];

    constructor() {
        makeObservable(this, {
            tables: observable,
            teams: observable,
            initTeams: action,
            initTabels: action,
        });

        this.initTeams();
        this.initTabels();
    }

    async initTeams() {
        const respTeams = await http.get<TeamsListElement>(
            // TODO: teams === competition_id
            `${API_URL}teams/${COMPETITION_ID}/`,
        );

        console.log('MainStore initTeams');
        runInAction(() => {
            this.teams = respTeams.data.params.map((el) => ({
                id: el.id,
                tables: el.tables,
            }));
        });
    }

    async initTabels() {
        const respTables = await http.get<TableListElement[]>(
            `${API_URL}table/`,
        );

        runInAction(() => {
            this.tables = respTables.data.map((el) => ({
                id: el.id,
                hash: el.hash,
                name: el.name,
                params: el.params,
            }));
        });

        await sleep();

        await this.initTabels();
    }

    scores(tableId: number, currentRound: number) {
        return this.tables.find((el) => el.id === tableId)?.params?.rounds?.[
            currentRound
        ].scores;
    }

    scoresLast(tableId: number, currentRound: number) {
        const scores = this.tables.find((el) => el.id === tableId)?.params
            ?.rounds?.[currentRound].scores;
        const res = scores?.length ? scores[scores?.length - 1] : 0;
        return (res < 0 ? '' : '+') + res;
    }

    scoresSum(tableId: number, currentRound: number) {
        return (
            this.tables
                .find((el) => el.id === tableId)
                ?.params?.rounds?.[currentRound]?.scores?.reduce(
                    (prev, cur) => {
                        return prev + cur;
                    },
                    0,
                ) ?? 0
        );
    }
}

const mainStore = new MainStore();

export { mainStore };
