import { observer } from 'mobx-react';
import { FC } from 'react';
import {
    BrowserRouter,
    Link,
    Route,
    Routes,
    useParams,
} from 'react-router-dom';

import { TableComponent } from '../Table';
import { TableListElement, mainStore } from './store';
import { Host } from '../Host';
import { Results } from '../Results';

export interface IMain {}

const Menu: FC<{ tables: TableListElement[] }> = ({ tables }) => {
    return (
        <nav>
            <ul>
                <li>
                    <Link to={`/host/`}>Host</Link>
                </li>
                <li>
                    <Link to={`/roulette/`}>Roulette</Link>
                </li>
                {tables.map((el) => {
                    return (
                        <li key={el.id}>
                            <Link
                                to={`/table/${el.hash}/`}
                            >{`${el.name} (${el.id} / ${el.hash})`}</Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export const Main: FC<IMain> = observer((props) => {
    return (
        <BrowserRouter>
            <div>
                {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                {
                                    'Перейдите, пожалуйта, по ссылке, указанной в QR коде'
                                }
                            </>
                        }
                    />
                    <Route
                        path="/links/"
                        element={<Menu tables={mainStore.tables} />}
                    />
                    <Route
                        path="/table/:tableHash/"
                        element={<TableComponent />}
                    />
                    <Route path="/roulette/" element={<Results />} />
                    <Route path="/host/" element={<Host />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
});
