import React, { FC, useState } from 'react';
import * as THREE from 'three';
import { useThree } from "@react-three/fiber";
import { Text } from '@react-three/drei';
import { useSpring, animated } from '@react-spring/three';
//@ts-ignore
import InterBlack from '../Inter-Black.woff';
import { palette } from '../../ColorManagement';

interface LetterProps {
    letter: string;
    activeLetters: boolean[];
   // position?: any;
}

export const Letter: FC<LetterProps> = ({
    letter,
    activeLetters,
   
 //   position = [0, 0, 0],
}: LetterProps) => {
    // const [activeLetters, setActiveLetters] = useState(
    //     new Array<any>(letter.length).fill(false),
    // );

    // const keyPushEvent = React.useCallback((event: KeyboardEvent) => {
    //     switch (event.key) {
    //         case 'f':
    //             let old1 = activeLetters;
    //             old1[letter.indexOf('а')] = true;
    //             setActiveLetters([...old1]);
    //             //console.log (old1)
    //             break; // Flight
    //         case 'k':
    //             let old2 = activeLetters;
    //             old2[letter.indexOf('л')] = true;
    //             setActiveLetters([...old2]);
    //             break; // CameraControl
    //     }
    // }, []);

    // useEventListener('keydown', keyPushEvent);
    const { viewport } = useThree();
    return (
        <>
            <group dispose={null} position={[letter.length > 8 ? 5.55 - letter.length*0.65 : 3.1 - letter.length*0.41, 0.55, 0.1]} scale = {1.4}>
                {Object.entries(letter).map(([key, value], i) => (
                    <Cell
                        key={key}
                        active={activeLetters[i]}
                        text={letter[i]}
                        position={[i / 2.3, 0.2, 0]}
                        scale= {letter.length > 6 ? 13/(letter.length*2): 10/(letter.length*2)}
                    />
                ))}
            </group>
        </>
    );
};

interface CellProps {
    text: string;
    active: boolean;
    scale?: any;
    position?: any;
}

const Cell: React.VFC<CellProps> = ({
    active = false,
    text,
    scale = 1,
    position = [0, 0, 0],
}: CellProps) => {
    const { rotation } = useSpring({
        from: {
            rotation: active ? Math.PI : 0,
        },

        to: {
            rotation: active ? 0 : Math.PI,
        },

        config: {
            friction: 50,
        },
        delay: 10,
    });

    return (
        <group scale = {scale}>
            <animated.group
                dispose={null}
                rotation-y={rotation}
                position={position}
                
            >
                <mesh>
                    <boxGeometry attach="geometry" args={[0.4, 0.6, 0.01]} />
                    <meshBasicMaterial
                        attach="material"
                        color={active ? '#ffffff' : '#ffffff'}
                        side={THREE.DoubleSide}
                    />
                </mesh>
                <Text
                    fontSize={0.3}
                    maxWidth={2}
                    lineHeight={0.8}
                    letterSpacing={0.1}
                    textAlign={'center'}
                    font={InterBlack}
                    anchorX="center"
                    anchorY="middle"
                    position={[0, 0, 0.02]}
                    // rotation = {[0, 0, (2*Math.PI/2 - fromAngle) - Math.PI/Object.keys(RouletteSectors).length]}
                >
                    <meshBasicMaterial 
                        toneMapped={false}
                        color={palette.gray} 
                    />

                    {text.toUpperCase()}
                </Text>
              
            </animated.group>
        </group>
    );
};
