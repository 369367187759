import './styles.scss';

import { Main } from './Components/Main';
import { Toaster } from 'react-hot-toast';

export default function App() {
    return (
        <div className="App">
            <Main />
            <Toaster />
        </div>
    );
}
