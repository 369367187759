import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';

import { SpecialButtonCanvas } from '../SpecialButton';
import { useParams } from 'react-router-dom';
import { mainStore } from '../Main';

import './table.scss';
import { TableStore } from './store';

interface TableComponentProps {}

export const TableComponent: FC<TableComponentProps> = observer((props) => {
    let { tableHash } = useParams();

    const table = mainStore.tables.find((el) => el.hash === tableHash);

    let store: TableStore | null = table
        ? TableStore.getInstance(table.id)
        : null;

    useEffect(() => {
        return () => {
            store?.destroy();
            store = null;
        };
    }, []);

    if (table === undefined) {
        return (
            <>{`Ошибка: неправильный идентификатор стола: ${tableHash}. Такого стола не существует`}</>
        );
    }

    return (
        <>
            {/*
            <div className="sochi-table-title">{table.name}</div>
            */}
            {store && <SpecialButtonCanvas store={store} />}
        
        </>
        
    );
});
