import './host.scss';

import block from 'bem-cn-lite';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC, Fragment } from 'react';
import toast from 'react-hot-toast';

import { mainStore } from '../Main/store';
import { RadioGroup, radioGroupStore } from '../RadioGroup';
import { PrivateStore } from './privateStore';
import { CompetitionStore } from './store';

const b = block('sochi-host');
export interface IHost {}

export const HostButtons: FC<{
    showTotal: boolean;
}> = observer(({ showTotal }) => {
    const compStore = CompetitionStore.getInstance();
    return (
        <div className={b('buttons')}>
            {mainStore.teams.map((team) => {
                let teamSum = 0;

                return (
                    <div key={team.id}>
                        {team.tables.map((tableId) => {
                            const roundNumber =
                                tableId < 9
                                    ? 1
                                    : tableId < 17
                                    ? 2
                                    : tableId < 25
                                    ? 3
                                    : 4;
                            const tableSum = mainStore.scoresSum(
                                tableId,
                                roundNumber,
                            );
                            teamSum += tableSum;
                            return (
                                <Fragment key={tableId}>
                                    <div className={b('tableId')}>
                                        {roundNumber == 4? 'ㅤ' : tableId}
                                    </div>
                                    <button
                                        disabled={compStore.loading}
                                        className={b('button', {
                                            active:
                                                tableId ===
                                                compStore.active_table,
                                            selected:
                                                tableId ===
                                                compStore.selected_table,
                                        })}
                                        onClick={async (event) => {
                                            if (
                                                tableId !==
                                                compStore.selected_table
                                            ) {
                                                await compStore.select(tableId);
                                            }
                                        }}
                                    >
                                        { compStore.selected_table && tableId<= compStore.selected_table  && tableSum}
                                        {/*
                                        {tableSum!=0 && '/'}
                                        {tableSum!=0 && mainStore.scoresLast(
                                            tableId,
                                            compStore.current_round_id,
                                        )*/}
                                    </button>
                                </Fragment>
                            );
                        })}
                        {showTotal && (
                            <>
                                <br />
                                <div className={b('teamSum')}>
                                    <strong>{teamSum}</strong>
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
});

export const Host: FC<IHost> = observer(() => {
    const privateStore = PrivateStore.getInstance();
    if (!privateStore.settings) {
        console.warn('Ждём инициализации PrivateStore!');
        return null;
    }
    const { sectors, whole_word_score } = privateStore.settings;

    const compStore = CompetitionStore.getInstance();
    const currentSector = sectors.find(
        (el) => el.id === compStore.current_sector_id,
    );
    if (!currentSector) {
        console.warn('Ждём инициализации CompetitionStore и текущего сектора!');
        return null;
    }

    const currentWordObj = privateStore.currentWord(
        compStore.current_round_id,
        compStore.current_word_id,
    );
    const currentQuizObj = privateStore.currentQuiz(
        compStore.current_round_id,
        compStore.current_quiz_id,
    );

    return (
        <>
            {<HostButtons showTotal={false} />}
            <div>
                <div className={b('subbuttons')}>
                    <button
                        className={b('activate_table')}
                        disabled={
                            compStore.loading ||
                            (compStore.active_table !== null &&
                                compStore.active_table !==
                                    compStore.selected_table)
                        }
                        onClick={async () => {
                            if (compStore.active_table === null) {
                                await compStore.activate(
                                    compStore.selected_table,
                                );
                            } else {
                                await compStore.deactivate(
                                    compStore.selected_table,
                                );
                            }
                        }}
                    >
                        {compStore.active_table === null
                            ? 'Активировать кнопку на столе'
                            : compStore.selected_table !==
                              compStore.active_table
                            ? 'Активен стол ' + compStore.active_table
                            : 'Деактивировать кнопку на столе'}
                    </button>
                    <div>Ручное начисление баллов выделенного стола:</div>
                    <button
                        className={b('primary-button')}
                        disabled={compStore.loading}
                        onClick={() => {
                            compStore.plusMinus(compStore.selected_table, 50);
                        }}
                    >
                        +50
                    </button>
                    <button
                        className={b('primary-button')}
                        disabled={compStore.loading}
                        onClick={() => {
                            compStore.plusMinus(compStore.selected_table, 20);
                        }}
                    >
                        +20
                    </button>
                    <button
                        className={b('primary-button')}
                        disabled={compStore.loading}
                        onClick={() => {
                            compStore.plusMinus(compStore.selected_table, 5);
                        }}
                    >
                        +5
                    </button>
                    <button
                        className={b('primary-button')}
                        disabled={compStore.loading}
                        onClick={() => {
                            compStore.plusMinus(compStore.selected_table, -5);
                        }}
                    >
                        -5
                    </button>
                    <button
                        className={b('primary-button')}
                        disabled={compStore.loading}
                        onClick={() => {
                            compStore.plusMinus(compStore.selected_table, -20);
                        }}
                    >
                        -20
                    </button>
                </div>
                <div className={b('subtitle')}>
                    {currentSector.type === 'question' ? (
                        <>
                            <div>
                                <b>
                                    Квиз (x{currentSector.factor} если
                                    правильно) и {currentSector.score} бл. за
                                    букву
                                </b>
                            </div>
                            <div>
                                <b>Вопрос:</b> {currentQuizObj?.question}
                            </div>
                            <div>
                                <b>Ответ:</b> {currentQuizObj?.answer}
                            </div>
                            {/*
                            <div>
                                <b>Подсказки к слову:</b>{' '}
                                {currentWordObj?.hints.join('; ')}
                            </div>
                            */}
                        </>
                    ) : (
                        <>
                            <div className={b('select_letter')}>
                                <b>
                                    {!!currentSector.prize && 'Сектор приз!'}{' '}
                                    Выбери отгаданную букву: ({currentSector?.score} бл.
                                    за букву)
                                </b>
                            </div>
                            {/*
                            <div>
                                <b>Подсказки к слову:</b>{' '}
                                {currentWordObj?.hints.join('; ')}
                            </div>
                             */}
                       
                        </>
                    )}
                </div>
                <div className={b('answers')}>
                    <RadioGroup
                        loading={compStore.loading}
                        onChange={(event, valueId) => {
                            radioGroupStore.setValueId(valueId);
                        }}
                    ></RadioGroup>
                    <div className={b('letters')}>
                        {privateStore
                            .currentWordLetters(
                                compStore.current_round_id,
                                compStore.current_word_id,
                            )
                            .map((letter, i) => {
                                return (
                                    <button
                                        key={i}
                                        className={b('letterbutton', {
                                            closed: !compStore
                                                .current_opened_letters?.[i],
                                        })}
                                        onClick={() => {
                                            let count = 0;
                                            if (
                                                !currentSector ||
                                                !currentWordObj
                                            ) {
                                                return;
                                            }
                                            if (
                                                currentSector.type ===
                                                    'question' &&
                                                radioGroupStore.valueId === -1
                                            ) {
                                                toast.error(
                                                    'Необходимо ответить на вопрос квиза до выбора буквы!',
                                                );
                                                return;
                                            }
                                            const closedSet = new Set(
                                                Array.from(
                                                    currentWordObj.name,
                                                ).filter((el, i) => {
                                                    return !compStore
                                                        .current_opened_letters?.[
                                                        i
                                                    ];
                                                }),
                                            );
                                            if (closedSet.size === 1) {
                                                toast.error(
                                                    'Осталась только одна буква! Нажмите ОТКРЫТЬ СЛОВО ЦЕЛИКОМ!',
                                                );
                                                return;
                                            }
                                            const numOfLetters = (
                                                currentWordObj.name
                                                    .toLocaleUpperCase()
                                                    .match(
                                                        new RegExp(
                                                            letter.toLocaleUpperCase(),
                                                            'g',
                                                        ),
                                                    ) || []
                                            ).length;
                                            if (radioGroupStore.valueId !== 1) {
                                                // Не отгадали квиз или сектор без квиза:
                                                count =
                                                    currentSector.score *
                                                    numOfLetters;
                                            } else if (
                                                radioGroupStore.valueId === 1
                                            ) {
                                                // Отгадали квиз:
                                                count =
                                                    currentSector.score *
                                                    numOfLetters *
                                                    currentSector.factor;
                                            }
                                            if (
                                                confirm(
                                                    `Открыть ${
                                                        numOfLetters === 1
                                                            ? 'букву'
                                                            : 'буквы'
                                                    } ${letter.toLocaleUpperCase()} и зачислить ${count} бл.?`,
                                                )
                                            ) {
                                                compStore.openLetter({
                                                    tableId:
                                                        compStore.selected_table,
                                                    letter: letter.toLocaleUpperCase(),
                                                    count,
                                                    quiz: radioGroupStore.valueId,
                                                });
                                            }
                                        }}
                                    >
                                        {letter.toLocaleUpperCase()}
                                    </button>
                                );
                            })}
                    </div>
                    <button
                     className={b('fail')}
                        disabled={compStore.loading}
                        onClick={() => {
                            let count = 0;
                            if (!currentSector) {
                                return;
                            }
                            if (
                                currentSector.type === 'question' &&
                                radioGroupStore.valueId === -1
                            ) {
                                toast.error(
                                    'Необходимо ответить на вопрос квиза до выбора букв!',
                                );
                                return;
                            }
                            if (radioGroupStore.valueId !== 1) {
                                // Не отгадали букву + Не отгадали квиз или сектор без квиза:
                                count = 0;
                            } else if (radioGroupStore.valueId === 1) {
                                // Не отгадали букву + Отгадали квиз:
                                count = currentSector.score;
                            }
                            if (
                                confirm(
                                    `Не отгадали букву и зачислить ${count} бл.?`,
                                )
                            ) {
                                compStore.openLetter({
                                    tableId: compStore.selected_table,
                                    letter: ' ', // Отправляем пробел - это значит букву не отгадали.
                                    count,
                                    quiz: radioGroupStore.valueId,
                                });
                            }
                        }}
                    >
                        <div>Не отгадали букву</div>
                        <div>
                            {currentSector.type === 'question'
                                ? `(${currentSector.score} бл. если квиз - ДА)`
                                : '0 бл.'}
                        </div>
                    </button>
                    <button
                         className={b('secondary-button')}
                        disabled={compStore.loading}
                        onClick={() => {
                            let count = 0;
                            if (!currentSector) {
                                return;
                            }
                            if (
                                currentSector.type === 'question' &&
                                radioGroupStore.valueId === -1
                            ) {
                                toast.error(
                                    'Необходимо ответить на вопрос квиза до выбора букв!',
                                );
                                return;
                            }
                            if (radioGroupStore.valueId !== 1) {
                                // Не отгадали квиз или сектор без квиза:
                                count = whole_word_score;
                            } else if (radioGroupStore.valueId === 1) {
                                // Отгадали квиз:
                                count = whole_word_score * currentSector.factor;
                            }
                            if (
                                confirm(
                                    `Открыть ВСЕ буквы и зачислить ${count} бл.?`,
                                )
                            ) {
                                compStore.openLetter({
                                    tableId: compStore.selected_table,
                                    letter: 'all',
                                    count: count,
                                    quiz: radioGroupStore.valueId,
                                });
                            }
                        }}
                    >
                        <div>Отгадали слово целиком +
                        {`${whole_word_score} баллов ${
                            currentSector?.type === 'question'
                                ? ` (x${currentSector.factor} если квиз - ДА)`
                                : ''
                        }`}</div>
                    </button>
                </div>
                <div className={b('subbuttons')}>
                    {/*
                    <div>
                        <button
                            className={b('secondary-button')}
                            disabled={
                                compStore.loading ||
                                currentSector.type !== 'question'
                            }
                            onClick={async () => {
                                await compStore.nextQuiz();
                            }}
                        >
                            Предыдущий квиз
                        </button>
                    </div>
                    <div>
                        <button
                            className={b('secondary-button')}
                            disabled={
                                compStore.loading ||
                                currentSector.type !== 'question'
                            }
                            onClick={async () => {
                                await compStore.prevQuiz();
                            }}
                        >
                            Следующий квиз
                        </button>
                    </div>
                    */}
                    <div>
                        <button
                            className={b('secondary-button')}
                            disabled={compStore.loading}
                            onClick={async () => {
                                const isAllLettersOpened =
                                    !!compStore.current_opened_letters
                                        ?.length &&
                                    compStore.current_opened_letters.every(
                                        (el) => !!el,
                                    );
                                if (!isAllLettersOpened) {
                                    if (
                                        confirm(
                                            `Ещё НЕ все буквы открыты! Закрыть ВСЕ буквы и перейти к следующему слову?`,
                                        )
                                    ) {
                                        await compStore.nextWord();
                                    }
                                } else {
                                    await compStore.nextWord();
                                }
                            }}
                        >
                            Следующее слово
                            
                        </button>
                    </div>
                </div>
                <div className={b('subbuttons')}>
                    <label>
                        <input
                            type="number"
                            className={b('input-text')}
                            disabled={compStore.loading}
                            value={compStore._meta.round_len_min ?? 10}
                            onChange={(event) => {
                                console.log(parseInt(event.target.value));
                                runInAction(() => {
                                    compStore._meta.round_len_min = parseInt(
                                        event.target.value,
                                    );
                                });
                            }}
                        ></input>
                        МИНУТ
                    </label>
                    <button
                        className={b('secondary-button')}
                        disabled={compStore.loading}
                        onClick={async () => {
                            if (confirm(`Желаете запустить таймер заново?`)) {
                                await compStore.setMeta({
                                    round_len_sec:
                                        (compStore._meta.round_len_min ?? 10) *
                                        60,
                                    round_start_time: new Date().toISOString(),
                                });
                            }
                        }}
                    >
                        Запустить таймер нового раунда
                    </button>
                    <button
                        onClick={() => {
                            if (
                                confirm(
                                    `Уверены? Сбросить ВСЕ оценки и игру в начало?`,
                                )
                            ) {
                                if (confirm(`Точно уверены? Сбросить ВСЁ?`)) {
                                    compStore.clearScores();
                                }
                            }
                        }}
                        className={b('clear-button', [b('secondary-button')])}
                    >
                        Сброс всей игры
                    </button>

                </div>
            </div>
        </>
    );
});
