import {TextureLoader} from 'three'
import { MeshProps, useLoader, useThree, useFrame  } from "@react-three/fiber";

export const LOGO_RUSGIDRO_PATH = '../../assets/logoRusGidro.svg';
export const LOGO_OTKRITIE_PATH = '../../assets/logoOtkritie.svg';
export const TOP_PATTERN_PATH = '../../assets/pattern_top.png';
export const BOTTOM_PATTERN_PATH = '../../assets/pattern_bottom.png';
export const GIDROSTATION_PATH = '../../assets/gidroStation.png';

export const LogoRusGidro = (props: MeshProps) => {
    const texture = useLoader(TextureLoader, LOGO_RUSGIDRO_PATH)
    const { viewport } = useThree();
      return (
        <mesh scale = {viewport.width/6500} position={[-viewport.width/2.6,viewport.height/2.5,0]} {...props}>
          <planeGeometry
            attach="geometry"
            args={[987, 380]} 
          />
          <meshBasicMaterial
            attach="material"
            toneMapped = {false}
         //   side={THREE.DoubleSide}
            map={texture}
            transparent
          />
        </mesh>
      )
}

export const LogoOtkitie = (props: MeshProps) => {
  const texture = useLoader(TextureLoader, LOGO_OTKRITIE_PATH)
  const { viewport } = useThree();
    return (
      <mesh scale = {viewport.width/6500} position={[-viewport.width/5 ,viewport.height/2.5,0]} {...props}>
        <planeGeometry
          attach="geometry"
          args={[1035, 260]} 
        />
        <meshBasicMaterial
          attach="material"
          toneMapped = {false}
         // side={THREE.DoubleSide}
          map={texture}
          transparent
        />
      </mesh>
    )
}

export const TopPattern = (props: MeshProps) => {
  const texture = useLoader(TextureLoader, TOP_PATTERN_PATH)
  const { viewport } = useThree();
  let width = 1186, height = 552;
    return (
      <mesh scale = {viewport.height/1100} position={[viewport.width/4.91, viewport.height/4.0,-0.01]} {...props}>
        <planeGeometry
          attach="geometry"
          args={[width, height]} 
        />
        <meshBasicMaterial
          attach="material"
          toneMapped = {false}
        //  side={THREE.DoubleSide}
          map={texture}
         // color = {'#000000'}
          transparent
        />
      </mesh>
    )
}

export const BottomPattern = (props: MeshProps) => {
  const texture = useLoader(TextureLoader, BOTTOM_PATTERN_PATH)
  const { viewport } = useThree();
    return (
      <mesh scale = {viewport.width/1000} position={[viewport.width/4.91, -viewport.height/2.5,-0.02]} {...props}>
        <planeGeometry
          attach="geometry"
          args={[626, 374]} 
        />
        <meshBasicMaterial
          attach="material"         
          toneMapped = {false}
         // side={THREE.DoubleSide}
          map={texture}
          transparent
        />
      </mesh>
    )
}

export const GidroStation = (props: MeshProps) => {
  const texture = useLoader(TextureLoader, GIDROSTATION_PATH)
  const { viewport } = useThree();
    return (
      <mesh scale = {viewport.width/1500} position={[-viewport.width/3, -viewport.height/2.1,0.03]} {...props}>
        <planeGeometry
          attach="geometry"
          args={[ 973, 229]} 
        />
        <meshBasicMaterial
          attach="material"
          //side={DoubleSide}
          map={texture}
          toneMapped = {false}
          transparent
        />
      </mesh>
    )
}