import { observer } from 'mobx-react';
import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import {Shape, Vector3, BufferGeometry} from 'three'
import { dateTime, dateTimeParse } from '@gravity-ui/date-utils';
import { Html, Text, Line } from '@react-three/drei';
import { Canvas, useFrame, useThree } from '@react-three/fiber';

import { palette } from '../../ColorManagement';
//@ts-ignore
import fontDemi from '../Fonts/AvenirNextCyr-Demi.woff';
//@ts-ignore
import fontBlack from '../Fonts/AvenirNextCyr-Heavy.woff';
//@ts-ignore
import fontItalic from '../Fonts/AvenirNextCyr-Italic.woff';
//@ts-ignore
import fontRegular from '../Fonts/AvenirNextCyr-Regular.woff';
import { HostButtons } from '../Host';
import { PrivateStore } from '../Host/privateStore';
import { CompetitionStore } from '../Host/store';
import { Letter } from '../Letter';
import { Roulette } from '../Roulette';
import {
    BottomPattern,
    GidroStation,
    LogoOtkitie,
    LogoRusGidro,
    TopPattern,
} from './Branding';

export const Results: FC<{}> = observer(() => {
    const pStore = PrivateStore.getInstance();
    if (!pStore.settings) {
        console.warn('Ждём инициализации PrivateStore!');
        return null;
    }
    const { sectors } = pStore.settings;

    const compStore = CompetitionStore.getInstance();
    const currentWordObj = PrivateStore.getInstance().currentWord(
        compStore.current_round_id,
        compStore.current_word_id,
    );
    if (!currentWordObj) {
        console.warn('Ждём инициализации CompetitionStore и текущего слова!');
        return null;
    }

    const currentSector = sectors.find(
        (el) => el.id === compStore.current_sector_id,
    );

    const activeLetters =
        !!compStore.current_opened_letters?.length
            ? compStore.current_opened_letters
            : Array.from(currentWordObj.name).map((el) => false);

    // Есть задержка анимации, чтобы пользователи случайно не заметили правильные буквы заменим их на пробелы.
    const currentWord = Array.from(currentWordObj.name)
        .map((el, i) => {
            return activeLetters[i] ? el : ' ';
        })
        .join('');

    const shiftSeconds = compStore.shiftSeconds;
    // const CCounter = React.useMemo(() => {
    //     return (
    //     );
    // }, [shiftSeconds]);

    return (
        <>
            {/* <div>{roundIds.map((round) => {
                return tableIds.map((table) => {
                    return mainStore.scoresSum(table, round);
                });
            })}</div> */}

            <Canvas
                gl={{ alpha: true }}
                camera={{
                    fov: 60,
                    far: 60000,
                }}
                onCreated={({ gl }) => {
                    gl.setClearColor('#ffffff');
                }}
                style={{
                    zIndex: -1,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '2048px',
                    height: '1408px',
                }}
            >
                <ambientLight intensity={0.9} />
                <spotLight
                    position={[0, 3, 0]}
                    angle={0.15}
                    penumbra={1}
                    intensity={0.0}
                />
                <group position={[-2.7, 1.6, 0]} scale={1.4}>
                    <Text
                        fontSize={0.3}
                        maxWidth={2}
                        lineHeight={0.8}
                        letterSpacing={0}
                        textAlign="center"
                        font={fontDemi}
                        anchorX="center"
                        anchorY="middle"
                        position={[0, 0, 0]}
                        rotation={[0, 0, 0]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.gray}
                        />
                        {compStore.current_round_id}-й РАУНД
                    </Text>

                    <Text
                        fontSize={0.1}
                        maxWidth={2.9}
                        lineHeight={0.95}
                        letterSpacing={0.0}
                        textAlign="center"
                        font={fontRegular}
                        anchorX="center"
                        anchorY="top"
                        position={[3.3, -0.95, 0]}
                        rotation={[0, 0, 0]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.black}
                        />

                        {currentWordObj.description}
                    </Text>

                    {!!shiftSeconds && shiftSeconds > 0 && (
                        <Counter
                            roundNumber={compStore.current_round_id}
                            timeSeconds={shiftSeconds}
                        />
                    )}
                </group>
                <LogoRusGidro />
                <LogoOtkitie />
                <TopPattern />
                <BottomPattern />
                <GidroStation />
                {!!sectors && !!currentSector && (
                    <Roulette
                        roullete={sectors}
                        currentSectorInfo={currentSector}
                    />
                )}
                <Letter letter={currentWord} activeLetters={activeLetters} />
                {/**/}
                <group name = "Table"  position={[0, -0.2, 0.03]}>
                <Html
                    occlude
                    scale={1.8}
                    position={[1.0, -1.1, 0]}
                    style={{
                        transition: 'all 0.5s',
                        opacity: 1,
                        transform: `scale(${2.0})`,
                    }}
                >
                    {<HostButtons showTotal={true} />}
                </Html>
                <Text
                        fontSize={0.1}
                        maxWidth={3.6}
                        lineHeight={0.95}
                        letterSpacing={0.0}
                        textAlign="left"
                        font={fontBlack}
                        anchorX="left"
                        anchorY="top"
                        position={[1.6, -0.37, 0]}
                        rotation={[0, 0, 0]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.gray}
                        />
                        КОМАНДЫ
                    </Text>


                <Text
                        fontSize={0.192}
                        maxWidth={3.6}
                        lineHeight={0.95}
                        letterSpacing={0.0}
                        textAlign="left"
                        font={fontBlack}
                        anchorX="left"
                        anchorY="top"
                        position={[0.2, -0.51, 0]}
                        rotation={[0, 0, 0]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.brandOrange}
                        />
                        1       2       3       4       5       6       7       8
                    </Text>

                <Text
                        fontSize={0.1}
                        maxWidth={2.9}
                        lineHeight={0.95}
                        letterSpacing={0.0}
                        textAlign="left"
                        font={fontBlack}
                        anchorX="center"
                        anchorY="top"
                        position={[-0.2, -0.83, 0]}
                        rotation={[0, 0, Math.PI/4]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.gray}
                        />
                        Раунд 1
                    </Text>
                    <Text
                        fontSize={0.1}
                        maxWidth={2.9}
                        lineHeight={0.95}
                        letterSpacing={0.0}
                        textAlign="left"
                        font={fontBlack}
                        anchorX="center"
                        anchorY="top"
                        position={[-0.2, -1.23, 0]}
                        rotation={[0, 0, Math.PI/4]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.gray}
                        />
                        Раунд 2
                    </Text>
                    <Text
                        fontSize={0.1}
                        maxWidth={2.9}
                        lineHeight={0.95}
                        letterSpacing={0.0}
                        textAlign="left"
                        font={fontBlack}
                        anchorX="center"
                        anchorY="top"
                        position={[-0.2, -1.63, 0]}
                        rotation={[0, 0, Math.PI/4]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.gray}
                        />
                        Раунд 3
                    </Text>
                    <Text
                        fontSize={0.1}
                        maxWidth={2.9}
                        lineHeight={0.95}
                        letterSpacing={0.0}
                        textAlign="left"
                        font={fontBlack}
                        anchorX="center"
                        anchorY="top"
                        position={[-0.24, -1.93, 0.05]}
                        rotation={[0, 0, Math.PI/4]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.gray}
                        />
                        Суперигра
                    </Text>
                    <Text
                        fontSize={0.1}
                        maxWidth={2.9}
                        lineHeight={0.95}
                        letterSpacing={0.0}
                        textAlign="left"
                        font={fontBlack}
                        anchorX="center"
                        anchorY="top"
                        position={[-0.24, -2.2, 0.05]}
                        rotation={[0, 0, Math.PI/4]}
                    >
                        <meshBasicMaterial
                            toneMapped={false}
                            color={palette.gray}
                        />
                       ИТОГО
                    </Text> 
                    {
                        compStore.selected_table && <Shape_1 position={[0.49 + 0.465*((compStore.selected_table-1)%8), -0.46, 0.08]} scale = {[0.44, 0.38, 1]}/>
                    }
                    
            </group>




            </Canvas>
        </>
    );
});

interface CounterProps {
    roundNumber: any;
    timeSeconds: number;
    //interval?: number;
    //props: any
}

export const Counter: FC<CounterProps> = ({
    roundNumber, //   position = [0, 0, 0],
    timeSeconds,
}: CounterProps) => {
    let mnt = Math.floor(timeSeconds / 60);
    let scd = timeSeconds - mnt * 60;
    if (mnt < 0) {
        mnt = 0;
    }
    if (scd < 0) {
        scd = 0;
    }
    // const [minutes, setMinutes] = useState(mnt);
    // const [seconds, setSeconds] = useState(scd);
    //const lastRoundNum = useRef<any>(0);
    const [flag, setFlag] = useState(false);
    useEffect(() => {
        //  console.log ("RN =", roundNumber);
        setFlag(true);
        // return ()=> {
        //   console.log ("changeRN =", roundNumber, lastRoundNum.current)
        //   lastRoundNum.current = roundNumber
        //
        // };
    }, [roundNumber]);

    useEffect(() => {
        //  roundNumber != lastRoundNum.current && console.log ("RN =", roundNumber, lastRoundNum)

        // let myInterval = setInterval(() => {
        //     if (seconds > 0) {
        //         setSeconds(seconds - 1);
        //     }
        //     if (seconds === 0) {
        //         if (minutes === 0) {
        //             clearInterval(myInterval);
        //         } else {
        //             setMinutes(minutes - 1);
        //             setSeconds(59);
        //         }
        //     }
        // }, 1000);

        if (flag) {
            //  console.log ("FLAG", roundNumber, lastRoundNum)
            setFlag(false);
            // setMinutes(mnt);
            // setSeconds(scd);
        }

        return () => {
            // clearInterval(myInterval);
        };
    });

    return (
        <>
            <Text
                fontSize={0.3}
                maxWidth={2}
                lineHeight={0.8}
                letterSpacing={0}
                textAlign="center"
                font={fontDemi}
                anchorX="center"
                anchorY="middle"
                position={[1.3, 0, 0]}
                rotation={[0, 0, 0]}
            >
                <meshBasicMaterial
                    toneMapped={false}
                    color={palette.brandBlue}
                />
                {mnt}:{scd < 10 ? `0${scd}` : scd}
            </Text>
        </>
    );
};

const Shape_1 = ({...props}: any) => {
    

    const points = []
    points.push(new Vector3(-1, 0, 0))
    points.push(new Vector3(0, 0, 0))
    points.push(new Vector3(0, -5, 0))
    points.push(new Vector3(-1, -5, 0))
    points.push(new Vector3(-1, 0, 0))
    //points.push(new Vector3(1, 0, 0))
    const lineGeometry = new BufferGeometry().setFromPoints(points)
      return (
        <group {...props}>
        <Line worldUnits points={points} color={palette.red} lineWidth={0.02}>
          <lineBasicMaterial attach="material" color={'#9c88ff'} linewidth={1} linecap={'round'} linejoin={'round'} />
        </Line>
        </group>
    )
  }
  
  //side={THREE.DoubleSide}