import './roulette.scss';
import React, { FC, useEffect, useState, useRef } from 'react';
import { DoubleSide, TextureLoader } from 'three';
import { MeshProps, useLoader, useThree, useFrame } from '@react-three/fiber';
import { animated, useSpring } from '@react-spring/three';
import { Text } from '@react-three/drei';
import { ISector } from '../Host/store';
//@ts-ignore
import fontBlack from '../Fonts/AvenirNextCyr-Heavy.woff';
//@ts-ignore
import fontRegular from '../Fonts/AvenirNextCyr-Regular.woff';
//@ts-ignore
import fontItalic from '../Fonts/AvenirNextCyr-Italic.woff';
//@ts-ignore
import fontDemi from '../Fonts/AvenirNextCyr-Demi.woff';

import { palette } from '../../ColorManagement';

export const LOGO_ROUND_PATH = '../../assets/logo_round.png';
export const LOGO_OTKRITIE_WT_PATH = '../../assets/logoOtkritieWT.png';

interface RouletteProps {
    currentSectorInfo: ISector;
    position?: any;
    roullete: ISector[];
}

export const Roulette: FC<RouletteProps> = ({
    currentSectorInfo,
    roullete,
    position = [0, 0, 0],
}: RouletteProps) => {
    const stopAtSector = currentSectorInfo.id;

   // const [on, setOn] = useState(false);
    const { viewport } = useThree();
    const [lastSector, setLastSector] = useState(0);
    const [rotationTimeCounter, setRotationTimeCounter] = useState(0);
    //const [lastAngle, setLastAngle] = useState(0);
    const [rotationTime, setRotationTime] = useState(false);
    const [springActivate, setSpringActivate] = useState(false);
    const group = useRef<any>()

    useEffect(() => {
       // setOn(!on);
         setRotationTime(true)
         setRotationTimeCounter(0);
         setSpringActivate (false)
        // setLastAngle ( whatAngle(roullete, lastSector))
         console.log("stopAtSector", stopAtSector)
        return () => {
            setLastSector(stopAtSector);
            //setAngle(0);
            //setLastAngle( whatAngle(roullete, stopAtSector))
            console.log("group.current.rotation.z", group.current.rotation.z)
        };
    }, [stopAtSector]);

    useEffect(() => {
        // setOn(!on);
        if (rotationTimeCounter < 250  ) { 
        // setLastAngle (angle)
        setRotationTimeCounter(rotationTimeCounter+1);
         //console.log("RLT", angle, group.current.rotation.z, stopAtSector)
        }
        else {
            setRotationTime(false)
            setSpringActivate (true)
           // setLastAngle(whatAngle(roullete, lastSector));
            console.log("RLT finish", group.current.rotation.z, whatAngle(roullete, stopAtSector), whatAngle(roullete, lastSector), (whatAngle(roullete, stopAtSector) - whatAngle(roullete, lastSector)) > 0)
           
        }
         return () => {
           // console.log("RLT return")
         };
     }, [rotationTimeCounter]);

     useFrame(({}) => {
         if (rotationTime) { 
            (whatAngle(roullete, stopAtSector) - whatAngle(roullete, lastSector)) > 0 ? 
            group.current.rotation.z += Math.PI*2/36 : 
            group.current.rotation.z -= Math.PI*2/36;
        }
        });
        
 
    //console.log('@@@ sector:', stopAtSector);

    const { rotation } = useSpring({
        from: {
            rotation: springActivate && group.current.rotation.z//whatAngle(roullete, lastSector),rotationTime?
        },

        to: {
            rotation: springActivate && whatAngle(roullete, stopAtSector),
        },

        config: {
        mass: 15,
        friction: 170,
        tension: 67,
        },
        delay: 3,
    });

    return (
        <group
            position={[-viewport.width / 3.9, -0.7, 0.1]}
            scale={1.55}
          
        >
            <LogoRound />
            <animated.group
                dispose={null}
                rotation-z={rotation}
                ref = {group}
            >
                <mesh>
                    <circleGeometry attach="geometry" args={[1.05, 64]} />
                    <meshBasicMaterial
                        attach="material"
                        toneMapped={false}
                        color={palette.gray}
                        side={DoubleSide}
                    />
                </mesh>

                <mesh position={[0, 0, 0.01]}>
                    <circleGeometry attach="geometry" args={[0.2, 64]} />
                    <meshBasicMaterial
                        attach="material"
                        toneMapped={false}
                        color={palette.brandOrange}
                        side={DoubleSide}
                    />
                </mesh>

                <mesh position={[0, 0, 0.011]}>
                    <circleGeometry attach="geometry" args={[0.18, 64]} />
                    <meshBasicMaterial
                        attach="material"
                        toneMapped={false}
                        color={palette.white}
                        side={DoubleSide}
                    />
                </mesh>

                

                {roullete.map((value, i) => (
                    <Circle
                        key={value.id}
                        fromAngle={(i * 2 * Math.PI) / roullete.length}
                        segmentValue={(2 * Math.PI) / roullete.length}
                        text={String(i + 1)}
                        sectorInfo={value}
                        roullete={roullete}
                    />
                ))}
            </animated.group>
            <Cone
                rotation={[0, 0, Math.PI - Math.PI / roullete.length]}
                scale={0.1}
                position={[
                    Math.sin(Math.PI / roullete.length),
                    Math.cos(Math.PI / roullete.length),
                    0,
                ]}
            />
        </group>
    );
};

interface SegmentProps {
    sectorInfo: ISector;
    fromAngle: number;
    segmentValue: number;
    text: string;
    position?: any;
    radius?: number;
    roullete: ISector[];
}

const Circle: FC<SegmentProps> = ({
    sectorInfo,
    fromAngle,
    segmentValue,
    text,
    position = [0, 0, 0],
    radius = 1,
    roullete,
}: SegmentProps) => {
    const sectorNumber = sectorInfo.id;
    // const color = sectorInfo.color || '#f39330';
    const type = sectorInfo.type;
    const sectorValue = sectorInfo.score;

    return (
        <group position={position}>
            <mesh>
                <circleGeometry
                    attach="geometry"
                    args={[radius, 32, fromAngle, segmentValue]}
                />
                <meshBasicMaterial
                    attach="material"
                    toneMapped={false}
                    color={sectorNumber % 2 == 0 ? palette.gray : palette.white}
                    side={DoubleSide}
                />
            </mesh>
            {type === 'letter' && (
                <Text
                    fontSize={0.16}
                    maxWidth={2}
                    lineHeight={0.8}
                    letterSpacing={0}
                    textAlign="center"
                    font={fontDemi}
                    anchorX="center"
                    anchorY="middle"
                    position={[
                        0.87 *
                            radius *
                            Math.sin(fromAngle + Math.PI / roullete.length),
                        0.87 *
                            radius *
                            Math.cos(fromAngle + Math.PI / roullete.length),
                        0.002,
                    ]}
                    rotation={[
                        0,
                        0,
                        (2 * Math.PI) / 2 -
                            fromAngle -
                            Math.PI / roullete.length,
                    ]}
                >
                    <meshBasicMaterial
                        toneMapped={false}
                        color={
                            sectorNumber % 2 != 0 ? palette.gray : palette.white
                        }
                    />

                    {sectorValue} 
                </Text>
            )}
            { sectorNumber == 9 && (  //type === 'question'
                <Text
                    fontSize={0.12}
                    maxWidth={2}
                    lineHeight={0.8}
                    letterSpacing={0}
                    textAlign="center"
                    font={fontDemi}
                    anchorX="center"
                    anchorY="middle"
                    position={[
                        0.57 *
                            radius *
                            Math.sin(fromAngle + Math.PI / roullete.length),
                        0.57 *
                            radius *
                            Math.cos(fromAngle + Math.PI / roullete.length),
                        0.002,
                    ]}
                    rotation={[
                        0,
                        0,
                        (2 * Math.PI) / 2 -
                            fromAngle -
                            Math.PI / roullete.length +
                            Math.PI / 2,
                    ]}
                >
                    <meshBasicMaterial
                        toneMapped={false}
                        color={palette.gray}
                    />
                    КВИЗ
                </Text>
            )}
            {!!sectorInfo.prize && (
                <LogoOtkitieWT
                    position={[
                        0.52 *
                            radius *
                            Math.sin(fromAngle + Math.PI / roullete.length),
                        0.52 *
                            radius *
                            Math.cos(fromAngle + Math.PI / roullete.length),
                        0.002,
                    ]}
                    rotation={[
                        0,
                        0,
                        (2 * Math.PI) / 2 -
                            fromAngle -
                            Math.PI / roullete.length +
                            (3 * Math.PI) / 2,
                    ]}
                />
            )}
        </group>
    );
};

const Cone = ({ ...props }: any) => {
    return (
        <mesh {...props}>
            <coneGeometry attach="geometry" args={[1, 3, 32]} />
            <meshBasicMaterial
                toneMapped={false}
                attach="material"
                color={palette.brandOrange}
            />
        </mesh>
    );
};

const whatAngle = (roullete: ISector[], sectorId: number) => {
    let angle = 0;

    {
        roullete.map((sector, i) => {
            if (sectorId === sector.id) {
                angle = (i * 2 * Math.PI) / roullete.length;
            }
        });
    }
    return angle;
};

export const LogoOtkitieWT = (props: MeshProps) => {
    const texture = useLoader(TextureLoader, LOGO_OTKRITIE_WT_PATH);
    const { viewport } = useThree();
    return (
        <mesh scale={viewport.width / 20500} position={[0, 0, 0.01]} {...props}>
            <planeGeometry attach="geometry" args={[1035, 260]} />
            <meshBasicMaterial
                attach="material"
                toneMapped={false}
                // side={THREE.DoubleSide}
                map={texture}
                transparent
            />
        </mesh>
    );
};

export const LogoRound = (props: MeshProps) => {
    const texture = useLoader(TextureLoader, LOGO_ROUND_PATH);
    const { viewport } = useThree();
    return (
        <mesh scale={viewport.width / 14000} position={[0, 0, 0.02]} {...props}>
            <planeGeometry attach="geometry" args={[305, 305]} />
            <meshBasicMaterial
                attach="material"
                toneMapped={false}
                // side={THREE.DoubleSide}
                map={texture}
                transparent
            />
        </mesh>
    );
};
