import { observable, makeObservable, action, runInAction } from 'mobx';
import { API_URL, http, sleep } from '../../constants';

export interface TableParams {
    rounds?: Record<
        string,
        {
            status: 'ready' | 'pressed' | 'answered';
            scores: number[];
        }
    >;
}

export class TableStore {
    /** nul === loading */
    params: TableParams | null = null;
    private destroyed = false;

    private constructor(public id: number) {
        makeObservable(this, {
            params: observable,
            init: action,
            destroy: action,
        });

        this.init();
    }

    static _instances: Record<string, TableStore> = {};

    static getInstance(id: number) {
        if (!(id in TableStore._instances)) {
            TableStore._instances[id] = new TableStore(id);
        }
        return TableStore._instances[id];
    }

    destroy() {
        this.destroyed = true;
        this.params = null;
        delete TableStore._instances[this.id];
    }

    async init() {
        if (this.destroyed) {
            return;
        }
        try {
            const resp = await http.get<{
                params: TableParams;
                id: string;
                name: string;
            }>(`${API_URL}table/${this.id}/`);

            runInAction(() => {
                console.log('init TableStore', this.id);
                this.params = resp.data.params;
            });
        } catch (error) {
            // TODO
            return;
        }

        await sleep();

        await this.init();
    }
}
