import * as axios from "axios";

export const http = axios.default;

export const API_URL = "https://sochi.ccode.pro/api/";

export const POOLLING_MS = 890;

export const COMPETITION_ID = 1;

export const sleep = (ms: number = POOLLING_MS) => {
  return new Promise((resolve) => setTimeout(resolve, POOLLING_MS));
};
