import { observable, makeObservable, action, runInAction, reaction } from 'mobx';
import { PrivateStore } from '../Host/privateStore';
import { CompetitionStore } from '../Host/store';

export class RadioGroupStore {
    disabled = true;
    
    constructor(public items: {id: number, name: string}[], public valueId: number = -1) {
        makeObservable(this, {
            items: observable,
            valueId: observable,
            disabled: observable,
            setValueId: action,
        });

        reaction(() => {
            const compStore = CompetitionStore.getInstance();
            const currentSector = PrivateStore.getInstance().settings?.sectors.find(
                (el) => el.id === compStore.current_sector_id,
            );
            return currentSector;
        }, (value, previousValue, reaction) => {
            this.valueId = -1;
            this.disabled = value?.type !== 'question';
         });
    }

    setValueId(valueId: number) {
        this.valueId = valueId;
    }
}
