import './RadioGroup.scss';

import block from 'bem-cn-lite';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { RadioGroupStore } from './store';

const b = block('sochi-radio-group');

export const radioGroupStore = new RadioGroupStore([
    { id: 0, name: 'Нет' },
    { id: 1, name: 'Да' },
]);

export const RadioGroup: FC<{
    loading: boolean;
    onChange: (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLSpanElement, MouseEvent>,
        valueId: number,
    ) => void;
}> = observer(({ onChange, loading }) => {
    const { items, valueId, disabled } = radioGroupStore;

    if (disabled) {
        return null;
    }

    return (
        <div className={b()}>
            {items.map((item, i) => {
                return (
                    <div className={b('radio')} key={i}>
                        <label>
                            <input
                                className={b('input')}
                                disabled={loading || disabled}
                                type="radio"
                                value={valueId}
                                checked={item.id === valueId}
                                onChange={(event) => onChange(event, item.id)}
                            />
                        </label>
                        <span onClick={(event) => onChange(event, item.id)}>
                            <b>{disabled ? '--' : item.name}</b>
                        </span>
                    </div>
                );
            })}
        </div>
    );
});
